import { language } from "./language";

const en: language = {
  translation: {
    Cancel: "Cancel",
    Categories: "Categories",
    "Create category": "Create category",
    "Create new classifier": "Create new classifier",
    "Delete category": "Delete category",
    "Delete images": "Delete images",
    Description: "Description",
    Edit: "Edit",
    "Edit category": "Edit category",
    Help: "Help",
    "Hide other categories": "Hide other categories",
    "Hide sidebar": "Hide sidebar",
    "Import images": "Import images",
    Logo: "Logo",
    Model: "Model",
    "Open classifier": "Open classifier",
    "Open example classifier": "Open example classifier",
    "Open weights": "Open weights",
    Open: "Open",
    "Run classifier": "Run classifier",
    "Save annotations and predictions": "Save annotations and predictions",
    "Save classifier": "Save classifier",
    "Save weights": "Save weights",
    Save: "Save",
    "Search images": "Search images",
    "Send feedback": "Send feedback",
    Settings: "Settings",
    "Show sidebar": "Show sidebar",
    Unknown: "Unknown",
  },
};

export default en;
