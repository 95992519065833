import { language } from "./language";

const fr: language = {
  translation: {
    "Actual size": "Taille réelle",
    "Add area": "Ajouter région",
    "Annotation mode": "Mode d'annotation",
    Cancel: "Annuler",
    Categories: "Catégories",
    "Center image automatically": "Centrer image automatiquement",
    "Color annotation": "Annotation couleur",
    "Create category": "Nouvelle catégorie",
    "Create new classifier": "Nouveau classifieur",
    Create: "Ajouter",
    "Delete category": "Supprimer catégorie",
    "Delete images": "Supprimer images",
    Description: "Description",
    Edit: "Modifier",
    "Edit category": "Modifier catégorie",
    "Elliptical annotation": "Annotation elliptique",
    Export: "Exporter",
    "Export annotations": "Exporter annotations",
    "Freehand annotation": "Annotation main-libres",
    Help: "Aide",
    "Hide category": "Cacher catégorie",
    "Hide other categories": "Cacher autres catégories",
    "Hide sidebar": "Cacher sidebar",
    "Import images": "Importer images",
    Intersection: "Intersection",
    "Invert annotation": "Inverser annotation",
    "Lasso annotation": "Annotation lasso",
    Logo: "Logo",
    "Magnetic annotation": "Annotation magnétique",
    Model: "Modèle",
    "Object annotation": "Annotation objet",
    "Open classifier": "Ouvrir classifieur",
    "Open example classifier": "Ouvrir example classifieur",
    "Open image": "Ouvrir image",
    "Open weights": "Ouvrir poids",
    Open: "Ouvrir",
    "Pen annotation": "Annotation pinceau",
    Pointer: "Pointeur",
    "Polygonal annotation": "Annotation polygonale",
    Name: "Nom",
    "New annotation": "Nouvelle annotation",
    "Quick annotation": "Annotation rapide",
    "Rectangular annotation": "Annotation rectangulaire",
    "Run classifier": "Initier classifieur",
    "Save annotations and predictions": "Sauver annotations et predictions",
    "Save classifier": "Sauver classifieur",
    "Save weights": "Sauver poids",
    Save: "Sauver",
    "Search images": "Rechercher images",
    "Send feedback": "Envoyer feedback",
    Settings: "Préférences",
    "Show category": "Montrer catégorie",
    "Show sidebar": "Montrer sidebar",
    "Subtract area": "Soustraire région",
    Unknown: "Inconnu",
    Zoom: "Zoom",
    "Zoom in": "Zoom intérieur",
    "Zoom mode": "Mode zoom",
    "Zoom out": "Zoom extérieur",
  },
};

export default fr;
