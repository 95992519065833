/**
 * @license
 * Copyright 2019 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

/*
 * NOTE:
 *
 * The tfjs coco ssd v2 has 80 labels, here:
 * https://github.com/tensorflow/tfjs-models/blob/master/coco-ssd/src/classes.ts
 *
 * but was converted from the TensorFlow Object Detection API, here:
 * https://github.com/tensorflow/models/blob/master/research/object_detection/README.md
 * having the same label map, here:
 * https://github.com/tensorflow/models/blob/master/research/object_detection/data/mscoco_label_map.pbtxt
 *
 * However COCO SSD, actually has a 90 class vector in its output, so 10 are missing from the label map
 * i.e. labels 12, 26, 29, 30, 45, 66, 68, 69, 71, 83
 *
 * The full label map can be found here:
 * https://github.com/tensorflow/models/blob/master/research/object_detection/data/mscoco_complete_label_map.pbtxt
 *
 * The full map is just the slimmer label map, but the 10 missing labels are put back in as dummies, having their
 * own index as their display name.
 *
 * The 80/90 discrepency is described in the original COCO paper, here:
 * https://arxiv.org/pdf/1405.0312.pdf
 *
 * "Our dataset contains 91 [90 + 1 background] object categories (the 2014 release contains segmentation
 *  masks for 80 of these categories)"
 *
 * More here:
 * https://stackoverflow.com/questions/50665110/tensorflow-models-uses-coco-90-class-ids-although-coco-has-only-80-categories
 *
 * I'm using the full map because it makes some logic in the CocoSSD Model Class a tiny bit easier to work with
 * but they can be disabled if we wish in the future (with corresponding changes in the code to account for it)
 */

const COCO_CLASSES: {
  [key: string]: { name: string; id: number; displayName: string };
} = {
  1: {
    name: "/m/01g317",
    id: 1,
    displayName: "person",
  },
  2: {
    name: "/m/0199g",
    id: 2,
    displayName: "bicycle",
  },
  3: {
    name: "/m/0k4j",
    id: 3,
    displayName: "car",
  },
  4: {
    name: "/m/04_sv",
    id: 4,
    displayName: "motorcycle",
  },
  5: {
    name: "/m/05czz6l",
    id: 5,
    displayName: "airplane",
  },
  6: {
    name: "/m/01bjv",
    id: 6,
    displayName: "bus",
  },
  7: {
    name: "/m/07jdr",
    id: 7,
    displayName: "train",
  },
  8: {
    name: "/m/07r04",
    id: 8,
    displayName: "truck",
  },
  9: {
    name: "/m/019jd",
    id: 9,
    displayName: "boat",
  },
  10: {
    name: "/m/015qff",
    id: 10,
    displayName: "traffic light",
  },
  11: {
    name: "/m/01pns0",
    id: 11,
    displayName: "fire hydrant",
  },
  12: { name: "12", id: 12, displayName: "12" },
  13: {
    name: "/m/02pv19",
    id: 13,
    displayName: "stop sign",
  },
  14: {
    name: "/m/015qbp",
    id: 14,
    displayName: "parking meter",
  },
  15: {
    name: "/m/0cvnqh",
    id: 15,
    displayName: "bench",
  },
  16: {
    name: "/m/015p6",
    id: 16,
    displayName: "bird",
  },
  17: {
    name: "/m/01yrx",
    id: 17,
    displayName: "cat",
  },
  18: {
    name: "/m/0bt9lr",
    id: 18,
    displayName: "dog",
  },
  19: {
    name: "/m/03k3r",
    id: 19,
    displayName: "horse",
  },
  20: {
    name: "/m/07bgp",
    id: 20,
    displayName: "sheep",
  },
  21: {
    name: "/m/01xq0k1",
    id: 21,
    displayName: "cow",
  },
  22: {
    name: "/m/0bwd_0j",
    id: 22,
    displayName: "elephant",
  },
  23: {
    name: "/m/01dws",
    id: 23,
    displayName: "bear",
  },
  24: {
    name: "/m/0898b",
    id: 24,
    displayName: "zebra",
  },
  25: {
    name: "/m/03bk1",
    id: 25,
    displayName: "giraffe",
  },
  26: { name: "26", id: 26, displayName: "26" },
  27: {
    name: "/m/01940j",
    id: 27,
    displayName: "backpack",
  },
  28: {
    name: "/m/0hnnb",
    id: 28,
    displayName: "umbrella",
  },
  29: { name: "29", id: 29, displayName: "29" },
  30: { name: "30", id: 30, displayName: "30" },
  31: {
    name: "/m/080hkjn",
    id: 31,
    displayName: "handbag",
  },
  32: {
    name: "/m/01rkbr",
    id: 32,
    displayName: "tie",
  },
  33: {
    name: "/m/01s55n",
    id: 33,
    displayName: "suitcase",
  },
  34: {
    name: "/m/02wmf",
    id: 34,
    displayName: "frisbee",
  },
  35: {
    name: "/m/071p9",
    id: 35,
    displayName: "skis",
  },
  36: {
    name: "/m/06__v",
    id: 36,
    displayName: "snowboard",
  },
  37: {
    name: "/m/018xm",
    id: 37,
    displayName: "sports ball",
  },
  38: {
    name: "/m/02zt3",
    id: 38,
    displayName: "kite",
  },
  39: {
    name: "/m/03g8mr",
    id: 39,
    displayName: "baseball bat",
  },
  40: {
    name: "/m/03grzl",
    id: 40,
    displayName: "baseball glove",
  },
  41: {
    name: "/m/06_fw",
    id: 41,
    displayName: "skateboard",
  },
  42: {
    name: "/m/019w40",
    id: 42,
    displayName: "surfboard",
  },
  43: {
    name: "/m/0dv9c",
    id: 43,
    displayName: "tennis racket",
  },
  44: {
    name: "/m/04dr76w",
    id: 44,
    displayName: "bottle",
  },
  45: { name: "45", id: 45, displayName: "45" },
  46: {
    name: "/m/09tvcd",
    id: 46,
    displayName: "wine glass",
  },
  47: {
    name: "/m/08gqpm",
    id: 47,
    displayName: "cup",
  },
  48: {
    name: "/m/0dt3t",
    id: 48,
    displayName: "fork",
  },
  49: {
    name: "/m/04ctx",
    id: 49,
    displayName: "knife",
  },
  50: {
    name: "/m/0cmx8",
    id: 50,
    displayName: "spoon",
  },
  51: {
    name: "/m/04kkgm",
    id: 51,
    displayName: "bowl",
  },
  52: {
    name: "/m/09qck",
    id: 52,
    displayName: "banana",
  },
  53: {
    name: "/m/014j1m",
    id: 53,
    displayName: "apple",
  },
  54: {
    name: "/m/0l515",
    id: 54,
    displayName: "sandwich",
  },
  55: {
    name: "/m/0cyhj_",
    id: 55,
    displayName: "orange",
  },
  56: {
    name: "/m/0hkxq",
    id: 56,
    displayName: "broccoli",
  },
  57: {
    name: "/m/0fj52s",
    id: 57,
    displayName: "carrot",
  },
  58: {
    name: "/m/01b9xk",
    id: 58,
    displayName: "hot dog",
  },
  59: {
    name: "/m/0663v",
    id: 59,
    displayName: "pizza",
  },
  60: {
    name: "/m/0jy4k",
    id: 60,
    displayName: "donut",
  },
  61: {
    name: "/m/0fszt",
    id: 61,
    displayName: "cake",
  },
  62: {
    name: "/m/01mzpv",
    id: 62,
    displayName: "chair",
  },
  63: {
    name: "/m/02crq1",
    id: 63,
    displayName: "couch",
  },
  64: {
    name: "/m/03fp41",
    id: 64,
    displayName: "potted plant",
  },
  65: {
    name: "/m/03ssj5",
    id: 65,
    displayName: "bed",
  },
  66: { name: "66", id: 66, displayName: "66" },
  67: {
    name: "/m/04bcr3",
    id: 67,
    displayName: "dining table",
  },
  68: { name: "68", id: 68, displayName: "68" },
  69: { name: "69", id: 69, displayName: "69" },
  70: {
    name: "/m/09g1w",
    id: 70,
    displayName: "toilet",
  },
  71: { name: "71", id: 71, displayName: "71" },
  72: {
    name: "/m/07c52",
    id: 72,
    displayName: "tv",
  },
  73: {
    name: "/m/01c648",
    id: 73,
    displayName: "laptop",
  },
  74: {
    name: "/m/020lf",
    id: 74,
    displayName: "mouse",
  },
  75: {
    name: "/m/0qjjc",
    id: 75,
    displayName: "remote",
  },
  76: {
    name: "/m/01m2v",
    id: 76,
    displayName: "keyboard",
  },
  77: {
    name: "/m/050k8",
    id: 77,
    displayName: "cell phone",
  },
  78: {
    name: "/m/0fx9l",
    id: 78,
    displayName: "microwave",
  },
  79: {
    name: "/m/029bxz",
    id: 79,
    displayName: "oven",
  },
  80: {
    name: "/m/01k6s3",
    id: 80,
    displayName: "toaster",
  },
  81: {
    name: "/m/0130jx",
    id: 81,
    displayName: "sink",
  },
  82: {
    name: "/m/040b_t",
    id: 82,
    displayName: "refrigerator",
  },
  83: { name: "83", id: 83, displayName: "83" },
  84: {
    name: "/m/0bt_c3",
    id: 84,
    displayName: "book",
  },
  85: {
    name: "/m/01x3z",
    id: 85,
    displayName: "clock",
  },
  86: {
    name: "/m/02s195",
    id: 86,
    displayName: "vase",
  },
  87: {
    name: "/m/01lsmm",
    id: 87,
    displayName: "scissors",
  },
  88: {
    name: "/m/0kmg4",
    id: 88,
    displayName: "teddy bear",
  },
  89: {
    name: "/m/03wvsk",
    id: 89,
    displayName: "hair drier",
  },
  90: {
    name: "/m/012xff",
    id: 90,
    displayName: "toothbrush",
  },
};

export default COCO_CLASSES;
