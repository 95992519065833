// prettier-ignore
import {language} from "./language";

const fi: language = {
  translation: {
    Cancel: "Peruuta",
    Categories: "Kategoriat",
    "Create category": "Luo kategoria",
    "Create new classifier": "Luo uusi luokittelija",
    "Delete category": "Poista kategoria",
    "Delete images": "Poista kuvat",
    Description: "Kuvaus",
    Edit: "Edit",
    "Edit category": "Muokkaa kategoriaa",
    Help: "Apua",
    "Hide other categories": "Piilota muut kategoriat",
    "Hide sidebar": "Piilota sivupalkki",
    "Import images": "Tuo kuvat",
    Logo: "Logo",
    Model: "Malli",
    "Open classifier": "Avaa luokittelija",
    "Open example classifier": "Avaa esimerkkiluokittelija",
    "Open weights": "Avaa painot",
    Open: "Avaa",
    "Run classifier": "Suorita luokittelija",
    "Save annotations and predictions":
      "Tallenna annotaatiot ja luokittelun tulos",
    "Save classifier": "Tallenna luokittelija",
    "Save weights": "Tallenna painot",
    Save: "Tallenna",
    "Search images": "Etsi kuvia",
    "Send feedback": "Lähetä palautetta",
    Settings: "Asetukset",
    "Show sidebar": "Näytä sivupalkki",
    Unknown: "Tuntematon",
  },
};

export default fi;
