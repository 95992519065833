const PiximiText = ({ loadPercent }: { loadPercent: number }) => {
  return (
    <>
      {/* Piximi */}
      <path
        fill={"url(#percent-fill"}
        d="M 50.859 15.825
             h 1.706
             c 1.407 0 2.525 -.394 3.355 -1.185.83 -.79 1.244 -1.88 1.244 -3.271 0 -2.339 -1.296 -3.508 -3.887 -3.508
             h -2.418
             z
             m 15.787 -4.503
             c 0 3.872 -1.142 6.862 -3.425 8.972 -2.284 2.11 -5.528 3.165 -9.731 3.165
             h -2.631
             v 11.496
             h -9.364
             V .3
             H 53.49
             c 4.377 0 7.665.956 9.861 2.868 2.197 1.912 3.295 4.63 3.295 8.153
             M 72.17.299
             h 9.41
             v 34.656
             h -9.41
             z
             M 120.29 34.955
             h -10.833
             l -6.756 -10.833 -6.685 10.833
             H 85.42
             l 11.497 -17.73
             L 86.107.298
             H 96.49
             l 6.259 10.714
             L 108.769.3
             h 10.692
             l -10.976 17.66
             z
             M 124.178.299
             h 9.41
             v 34.656
             h -9.41
             z
             M 179.696.298
             v 34.656
             h -8.387
             v -23.64
             l -6.714 23.64
             h -9.388
             l -6.71 -23.64
             v 23.64
             h -8.387
             V .298
             h 12.468
             l 7.228 24.605
             h .19
             L 167.228.298
             z
             M 186.073.299
             h 9.411
             v 34.656
             h -9.41
             z"
      />
      {/* TM */}
      <path
        fill={loadPercent >= 0 && loadPercent < 1 ? "#ffffff" : "#02aec5"}
        d="M 201.089 5.48
           h -.883
           V .839
           h -1.588
           V .081
           h 4.059
           v .757
           h -1.588
           z
           M 205.839 5.48 204.273.972
           h -.03
           c .042.67.063 1.298.063 1.884
           V 5.48
           h -.802
           V .08
           h 1.245
           l 1.5 4.296
           h .022
           L 207.815.08
           h 1.248
           v 5.4
           h -.85
           V 2.81
           c 0 -.269.007 -.618.02 -1.05.015 -.43.026 -.691.036 -.783
           h -.03
           l -1.62 4.503
           z"
      />
    </>
  );
};

export const LogoLoader = ({
  width,
  height,
  loadPercent,
  fullLogo = true,
}: {
  width: number;
  height: number;
  loadPercent: number;
  fullLogo?: boolean;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={fullLogo ? "0 0 209.063 34.957" : "0 0 35.993 34.957"}
    >
      <defs>
        <filter
          id="indefinite-fill"
          primitiveUnits="objectBoundingBox"
          x="0%"
          y="0%"
          width="100%"
          height="100%"
        >
          <feFlood
            x="0%"
            y="0%"
            width="100%"
            height="100%"
            floodColor={"#02aec5"}
          />

          <feOffset>
            <animate
              attributeName={"dy"}
              from={1}
              to={0}
              dur={"3s"}
              repeatCount={"indefinite"}
            />
          </feOffset>
          <feComposite operator="in" in2="SourceGraphic" />
          <feComposite operator="over" in2="SourceGraphic" />
        </filter>

        {fullLogo && (
          <linearGradient y2="0%" x2="100%" y1="0%" x1="0%" id="percent-fill">
            <stop
              stopColor="#02aec5"
              offset={`${
                (loadPercent < 0 ? 1 : loadPercent > 1 ? 1 : loadPercent) * 100
              }%`}
              id="F1gst1"
            />
            <stop stopColor="#ffffff" offset="0%" id="F1gst2" />
          </linearGradient>
        )}
      </defs>

      {/*
          MoveTo: M, m
          LineTo: L, l, H, h, V, v
          Cubic Bézier Curve: C, c, S, s
          Quadratic Bézier Curve: Q, q, T, t
          Elliptical Arc Curve: A, a
          ClosePath: Z, z
        */}

      {/* cyto */}
      <path
        fill={loadPercent < 0 ? "#ffffff" : "#02aec5"}
        filter={loadPercent < 0 ? `url(#indefinite-fill)` : undefined}
        d="M 24.094 34.955
           H 10.86
           C 4.862 34.955 0 30.092 0 24.094
           V 10.86
           C -.001 4.861 4.862 -.002 10.86 -.002
           h 13.234
           c 5.999 0 10.862 4.863 10.862 10.862
           v 13.234
           c 0 5.998 -4.863 10.861 -10.862 10.861"
      />
      {/* cyto */}
      <path
        fill={loadPercent < 0 ? "#ffffff" : "#02aec5"}
        filter={loadPercent < 0 ? `url(#indefinite-fill)` : undefined}
        d="M 24.094 34.955
             H 10.86
             C 4.862 34.955 0 30.092 0 24.094
             V 10.86
             C -.001 4.861 4.862 -.002 10.86 -.002
             h 13.234
             c 5.999 0 10.862 4.863 10.862 10.862
             v 13.234
             c 0 5.998 -4.863 10.861 -10.862 10.861"
      />
      {/* nucleus, white bg */}
      <path
        fill="#fff"
        d="M 29.286 17.477
             c 0 -6.522 -5.287 -11.809 -11.808 -11.809 -6.522 0 -11.81 5.287 -11.81 11.809
             s 5.288 11.808 11.81 11.808
             c 6.521 0 11.808 -5.286 11.808 -11.808"
      />
      {/* nucleus, right blue dots */}
      <path
        fill="#392dcf"
        d="M 23.657 22.141
             c -1.275 0 -2.308.334 -2.308.746
             s 1.033.746 2.308.746 2.309 -.334 2.309 -.746 -1.034 -.746 -2.309 -.746
             M 23.657 11.323
             c -1.275 0 -2.308.334 -2.308.745 0 .412 1.033.747 2.308.747
             s 2.309 -.335 2.309 -.747
             c 0 -.411 -1.034 -.745 -2.309 -.745"
      />
      {/* nucleus, blue lines + border dots */}
      <path
        fill="#392dcf"
        d="M 28.717 19.014
             c -2.862 -.17 -4.636 -.967 -4.636 -1.536 0 -.57 1.774 -1.367 4.633 -1.539 -.002 -.032 -.007 -.063 -.012 -.096
             l .015.096
             h -.003
             c .042.288.072.58.086.874 -.743.125 -1.274.376 -1.274.665 0 .29.53.55 1.274.672
             a 10.073 10.073 0 0 1 -.083.864
             m -.145.842
             h -.005
             a 10.01 10.01 0 0 1 -.343 1.24
             c -1.245 -.416 -2.908 -.631 -4.568 -.631 -2.584 0 -5.185.517 -6.179 1.514 -.994 -.997 -3.595 -1.514 -6.18 -1.514 -1.658 0 -3.322.215 -4.566.63
             a 9.888 9.888 0 0 1 -.343 -1.239
             h -.005
             a .169.169 0 0 1 -.008 -.049
             c .003.017.008.032.013.049 2.127 -.142 4.08 -.638 4.91 -1.47.992.994 3.595 1.512 6.18 1.512 2.583 0 5.187 -.518 6.178 -1.512.83.832 2.784 1.328 4.911 1.47.005 -.017.01 -.032.012 -.049
             a .181.181 0 0 1 -.007.049
             m -1.97 4.356
             a 16.71 16.71 0 0 1 -2.946.245
             c -3.511 0 -5.754 -.93 -5.754 -1.57
             s 2.243 -1.573 5.754 -1.573
             c 1.81 0 3.274.248 4.278.574
             a 11.071 11.071 0 0 1 -1.333 2.324
             m -3.212 2.95
             c -1.139 -.847 -3.531 -1.289 -5.912 -1.289 -2.38 0 -4.773.442 -5.911 1.289
             a 11.335 11.335 0 0 1 -2.41 -1.978 18.5 18.5 0 0 0 2.142.125
             c 2.584 0 5.185 -.52 6.18 -1.514.993.994 3.594 1.514 6.178 1.514.727 0 1.45 -.044 2.143 -.125
             a 11.335 11.335 0 0 1 -2.41 1.978
             m -3.843 1.457
             c .15 -.098.24 -.206.24 -.323 0 -.413 -1.033 -.747 -2.309 -.747 -1.276 0 -2.31.334 -2.31.747 0 .117.092.225.241.323 -.372 -.086 -1.926 -.456 -3.008 -.996.913 -.474 2.704 -.898 5.077 -.898 2.373 0 4.165.424 5.078.898 -1.083.54 -2.636.91 -3.009.996
             M 8.353 24.212
             a 11.04 11.04 0 0 1 -1.332 -2.324
             c 1.003 -.326 2.469 -.574 4.277 -.574 3.512 0 5.755.932 5.755 1.573 0 .64 -2.243 1.57 -5.755 1.57 -1.124 0 -2.113 -.095 -2.945 -.245
             M 6.154 18.15
             c .744 -.122 1.274 -.382 1.274 -.672 0 -.29 -.53 -.54 -1.274 -.665.015 -.294.044 -.586.086 -.874
             h -.002
             l .014 -.096
             c -.005.033 -.01.064 -.012.096 2.86.172 4.633.97 4.633 1.539 0 .57 -1.774 1.367 -4.635 1.536
             a 9.934 9.934 0 0 1 -.084 -.864
             m 17.078 -.672
             c 0 .64 -2.24 1.57 -5.755 1.57 -3.514 0 -5.754 -.93 -5.754 -1.57
             s 2.24 -1.573 5.754 -1.573
             c 3.515 0 5.755.933 5.755 1.573
             M 6.382 15.1
             h .006
             c .088 -.425.2 -.84.338 -1.244 1.244.42 2.91.633 4.572.633 2.584 0 5.185 -.518 6.18 -1.514.993.996 3.594 1.514 6.178 1.514 1.662 0 3.328 -.213 4.573 -.633.137.405.25.82.338 1.244
             h .005
             a .18.18 0 0 1 .007.05
             c -.002 -.018 -.007 -.033 -.012 -.05 -2.127.14 -4.081.638 -4.91 1.47 -.992 -.996 -3.596 -1.514 -6.18 -1.514 -2.584 0 -5.187.518 -6.18 1.514 -.828 -.832 -2.782 -1.33 -4.91 -1.47 -.004.017 -.01.032 -.012.05 0 -.018.003 -.033.008 -.05
             m 1.965 -4.356
             c .02 -.027.04 -.054.062 -.08
             a .835.835 0 0 0 -.06.08
             c .833 -.152 1.821 -.248 2.948 -.248 3.512 0 5.755.93 5.755 1.573 0 .64 -2.243 1.571 -5.755 1.571 -1.808 0 -3.274 -.248 -4.277 -.574
             a 11.244 11.244 0 0 1 1.33 -2.322
             z
             m .808 -.974
             h .002
             a 11.313 11.313 0 0 1 2.408 -1.976
             c 1.138.847 3.531 1.286 5.911 1.286 2.38 0 4.773 -.439 5.912 -1.286
             a 11.313 11.313 0 0 1 2.407 1.976
             h .003
             l .066.074
             c -.022 -.025 -.044 -.05 -.069 -.074
             a 18.473 18.473 0 0 0 -2.14 -.123
             c -2.584 0 -5.185.518 -6.179 1.514 -.994 -.996 -3.595 -1.514 -6.18 -1.514 -.725 0 -1.447.042 -2.139.123
             a 1.471 1.471 0 0 0 -.069.074
             l .067 -.074
             m 6.275 -3.453
             c -.155.098 -.263.223 -.263.341 0 .413 1.033.746 2.31.746 1.275 0 2.308 -.333 2.308 -.746 0 -.118 -.107 -.243 -.262 -.341
             a 11.29 11.29 0 0 1 3.03 1.016
             c -.912.474 -2.704.898 -5.077.898 -2.373 0 -4.164 -.424 -5.077 -.898
             a 11.29 11.29 0 0 1 3.03 -1.016
             m 11.115 4.346
             c .022.027.042.054.061.081
             h -.002
             a 11.277 11.277 0 0 1 1.33 2.322
             c -1.004.326 -2.469.574 -4.278.574 -3.511 0 -5.754 -.93 -5.754 -1.57 0 -.644 2.243 -1.574 5.754 -1.574 1.127 0 2.116.096 2.948.248
             a .9.9 0 0 0 -.059 -.08
             m -9.068 -5.942
             c -7.033 0 -12.756 5.723 -12.756 12.756
             s 5.723 12.754 12.756 12.754
             c 7.034 0 12.756 -5.72 12.756 -12.754 0 -7.033 -5.722 -12.756 -12.756 -12.756"
      />
      {/* nucleus, center and left blue dots */}
      <path
        fill="#392dcf"
        d="M 19.786 17.478
             c 0 .206 -.257.393 -.677.528 -.417.134 -.994.218 -1.632.218 -.638 0 -1.214 -.084 -1.632 -.218 -.42 -.135 -.677 -.322 -.677 -.528 0 -.412 1.033 -.746 2.31 -.746 1.275 0 2.308.334 2.308.746
             M 11.298 22.141
             c 1.274 0 2.308.334 2.308.746
             s -1.034.746 -2.308.746
             c -1.275 0 -2.31 -.334 -2.31 -.746
             s 1.035 -.746 2.31 -.746
             M 11.298 11.323
             c 1.274 0 2.308.334 2.308.745 0 .412 -1.034.747 -2.308.747 -1.275 0 -2.31 -.335 -2.31 -.747 0 -.411 1.035 -.745 2.31 -.745"
      />

      {fullLogo && PiximiText({ loadPercent })}
    </svg>
  );
};
