// prettier-ignore
import {language} from "./language";

const gr: language = {
  translation: {
    Cancel: "Άκυρο",
    Categories: "Κατηγορίες",
    "Create category": "Δημιουργία κατηγορίας",
    "Create new classifier": "Νέος ταξινομητής",
    "Delete category": "Διαγραφή κατηγορίας",
    "Delete images": "Διαγραφή εικόνων",
    Description: "Περιγραφή",
    Edit: "Επεξεργασία",
    "Edit category": "Επεξεργασία κατηγορίας",
    Help: "Βοήθεια",
    "Hide other categories": "Απόκρυψη άλλων κατηγοριών",
    "Hide sidebar": "Απόκρυψη πλευρικής γραμμής",
    "Import images": "Εισαγωγή εικόνων",
    Logo: "Λογότυπο",
    Model: "Mοντέλο",
    "Open classifier": "Άνοιγμα ταξινομητή",
    "Open example classifier": "Παράδειγμα ταξινομητή",
    "Open weights": "Άνοιγμα παραμέτρων",
    Open: "Άνοιγμα",
    "Run classifier": "Εκτέλεση ταξινομητή",
    "Save annotations and predictions": "Αποθήκευση ετικετών και προβλέψεων",
    "Save classifier": "Αποθήκευση ταξινομητή",
    "Save weights": "Αποθήκευση παραμέτρων",
    Save: "Αποθήκευση",
    "Search images": "Αναζήτηση εικόνων",
    "Send feedback": "Eπικοινωνια",
    Settings: "Ρυθμίσεις",
    "Show sidebar": "Εμφάνιση πλευρικής γραμμής",
    Unknown: "Άγνωστο",
  },
};

export default gr;
