// prettier-ignore
import {language} from "./language";

const de: language = {
  translation: {
    Cancel: "Abbrechen",
    Categories: "Kategorien",
    "Create category": "Neue Kategorie",
    "Create new classifier": "Neuer Classifier",
    "Delete category": "Lösche Kategorie",
    "Delete images": "Lösche Bilder",
    Description: "Beschreibung",
    Edit: "Edit",
    "Edit category": "Bearbeite Kategorie",
    Help: "Hilfe",
    "Hide other categories": "Blende andere Kategorien aus",
    "Hide sidebar": "Blende Sidebar aus",
    "Import images": "Bilder hinzufügen",
    Logo: "Logo",
    Model: "Modell",
    "Open classifier": "Öffne Classifier",
    "Open example classifier": "Öffne Beispiel Classifier",
    "Open weights": "Öffne Gewichte",
    Open: "Öffnen",
    "Run classifier": "Starte Classfier",
    "Save annotations and predictions": "Speicher Labels und Predictions",
    "Save classifier": "Speicher Classifier",
    "Save weights": "Speicher Gewichte",
    Save: "Speichern",
    "Search images": "Durchsuche Bilder",
    "Send feedback": "Sende Feedback",
    Settings: "Einstellungen",
    "Show sidebar": "Blende Sidebar ein",
    Unknown: "Unbekannt",
  },
};

export default de;
