// prettier-ignore
import {language} from "./language";

const hu: language = {
  translation: {
    Cancel: "Mégse",
    Categories: "Osztályok",
    "Create category": "Új osztály létrehozása",
    "Create new classifier": "Új osztályozó létrehozása",
    "Delete category": "Osztály törlése",
    "Delete images": "Képek törlése",
    Description: "Leírás",
    Edit: "Edit",
    "Edit category": "Kategória szerkesztése",
    Help: "Súgó",
    "Hide other categories": "Többi osztály elrejtése",
    "Hide sidebar": "Oldalsáv elrejtése",
    "Import images": "Képek importálása",
    Logo: "Logó",
    Model: "Modell",
    "Open classifier": "Osztályozó betöltése",
    "Open example classifier": "Példa osztályozó betöltése",
    "Open weights": "Súlyok betöltése",
    Open: "Megnyitás",
    "Run classifier": "Osztályozó futtatása",
    "Save annotations and predictions": "Címkék és predikciók mentése",
    "Save classifier": "Osztályozó mentése",
    "Save weights": "Súlyok mentése",
    Save: "Mentés",
    "Search images": "Képek keresése",
    "Send feedback": "Visszajelzés küldése",
    Settings: "Beállítások",
    "Show sidebar": "Oldalsáv megjelenítése",
    Unknown: "Ismeretlen",
  },
};

export default hu;
